import styled from "@emotion/styled";
import { theme } from "../../theme/theme";

export const Container = styled("div")(({ isMobile }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  width: "100%",
  padding: isMobile ? "100px 16px" : "120px",
  backgroundColor: theme.color.primary[2],
}));

export const ContainerItems = styled("div")(({ isMobile }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: isMobile ? "column" : "row",
  width: "100%",
  gap: "8px",
  backgroundColor: theme.color.primary[2],
}));

export const Button = styled("button")(() => ({
  display: "flex",
  alignItems: "center",
  color: theme.color.primary[2],
  background: "transparent",
  marginLeft: "-40px",
  marginTop: "-56px",
  height: "40px",
  border: "none",
  borderRadius: "40px",
  width: "fit-content",
  overflow: "hidden",
  gap: "24px",
  zIndex: 10,
  svg: {
    position: "absolute",
    overflow: "hidden",
    zIndex: 10,
    borderRadius: "40px",
    backgroundColor: theme.color.primary[9],
    width: "40px",
    padding: "8px",
    fill: theme.color.primary[2],
  },
  "&:active": {
    backgroundColor: theme.color.black,
    transform: "scale(0.9)",
  },
  "&:hover": {
    svg: {
      borderRight: "none",
    },
    cursor: "pointer",
  },
}));

export const TextButton = styled("p")(() => ({
  margin: 0,
  fontSize: "16px",
  padding: "10px 16px 8px 64px",
  transform: "translateX(-100%)",
  zIndex: 1,
  transition: "opacity 0.4s, transform 0.4s, visibility 0.4s",
  textWrap: "nowrap",
  backgroundColor: theme.color.primary[9],
  borderRadius: "0 40px 40px 0",
  height: "40px",
  marginLeft: "-24px",
  "&.visible": {
    transition: "0.2s",
    transform: "translateX(0)",
    span: {
      opacity: 1,
      transition: "opacity 0.6s",
    },
  },
  span: {
    opacity: 0,
  },
}));

export const TitleInformation = styled("h2")(() => ({
  padding: "8px 40px 8px 16px",
  fontSize: "32px",
  borderRight: "none",
  borderRadius: "40px 0 0 40px",
  backgroundColor: theme.color.black,
  color: theme.color.primary[2],
  marginBottom: "24px",
  textWrap: "wrap",
  zIndex: 10,
}));

export const Item = styled("div")(({ isMobile }) => ({
  display: "flex",
  width: isMobile ? "100%" : "48%",
  padding: "16px",
  gap: "16px",
  border: `1px solid ${theme.color.primary[1]}`,
  borderRadius: "8px",
}));

export const ItemTitle = styled("div")(() => ({
  display: "flex",
  fontWeight: "bold",
  gap: "8px",
  fontSize: "16px",
  alignItems: "center",
  color: theme.color.black,
  width: "100%",
  padding: "8px 0",
}));

export const ItemDesc = styled("div")(() => ({
  padding: "8px 0px",
}));

export const ListStyled = styled("ul")(({ isMobile }) => ({
  display: "grid",
  gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
  flexWrap: "wrap",
  listStyleType: "none",
  gap: "8px",
  gridColumnGap: "16px",
}));

export const FeatureItem = styled("li")(({ isViewMore = false }) => ({
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  textWrap: "nowrap",
  padding: "8px 16px",
  border: `1px solid ${theme.color.primary[1]}`,
  color: isViewMore ? theme.color.primary[2] : theme.color.primary[1],
  backgroundColor: isViewMore ? theme.color.primary[1] : theme.color.primary[2],
  width: isViewMore ? "fit-content" : "100%",
  svg: {
    fill: isViewMore ? theme.color.primary[2] : theme.color.primary[9],
  },
  "&:hover": {
    color: isViewMore ? theme.color.primary[1] : theme.color.primary[1],
    backgroundColor: theme.color.primary[2],
    cursor: isViewMore ? "pointer" : "inherit",
    transition: ".3s",
    svg: {
      fill: isViewMore ? theme.color.primary[1] : theme.color.primary[9],
    },
  },
}));
