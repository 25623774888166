const initialState = {
  store: {
    data: {},
    isLoading: false,
    isErrorMessage: '',
  },
}

//@ts-ignore
const storeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CREATE_STORE':
      return {
        ...state,
        store: {
          data: payload.data || state.store.data,
          isLoading: payload.isLoading,
          isErrorMessage: payload.isErrorMessage,
        },
      }
    default:
      return state
  }
}

export default storeReducer
