import emotionStyled from '@emotion/styled'
import { theme } from '../../theme/theme'

export const Container = emotionStyled('div')(({ isMobile }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  width: '100%',
  padding: isMobile ? '100px 16px' : '120px',
  backgroundColor: theme.color.primary[2],
}))

export const ListStyled = emotionStyled('ul')(({ isMobile }) => ({
  background: isMobile
    ? 'inherit'
    : `linear-gradient(rgba(0, 0, 0, 0.9), rgba(44, 86, 255, 1)), url('./assets/bg_plan.svg')`,
  width: '100%',
  gap: isMobile ? '24px' : '0',
  listStyleType: 'none',
  borderRadius: '24px',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridAutoRows: 'minmax(300px, auto)',
  justifyItems: 'stretch',
}))

export const Item = emotionStyled('li')(({ isMobile }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  backgroundColor: theme.color.black,
  background: isMobile
    ? `linear-gradient(rgba(0, 0, 0, 0.9), rgba(44, 86, 255, 1)), url('./assets/bg_plan.svg')`
    : 'transparent',
  color: theme.color.primary[2],
  borderRadius: isMobile ? '24px' : 0,
  border: `1px solid ${theme.color.primary[2]}`,
  padding: '16px 24px',
  // width: '40%',
  // flex: 1,
}))

export const PlanName = emotionStyled('h3')(() => ({
  fontSize: '32px',
}))

export const Price = emotionStyled('p')(() => ({
  color: theme.color.primary[1],
  backgroundColor: theme.color.primary[11],
  borderRadius: '4px',
  padding: '4px 24px',
  marginBottom: '16px',
  fontWeight: 'bold',
  fontStyle: 'italic',
  width: 'fit-content',
}))

export const ButtonContractStyled = emotionStyled('button')(() => ({
  backgroundColor: theme.color.primary[8],
  border: `1px solid ${theme.color.primary[8]}`,
  padding: '12px 24px',
  color: theme.color.black,
  fontSize: '16px',
  width: 'fit-content',
  borderRadius: '24px',
  transition: '.2s',
  '&:hover': {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.color.primary[9]}`,
    color: theme.color.primary[2],
    transition: '.2s',
    cursor: 'pointer',
  },
}))

export const ButtonAskStyled = emotionStyled('button')(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  backgroundColor: 'transparent',
  padding: '4px 0',
  color: theme.color.primary[2],
  border: 'none',
  fontSize: '16px',
  width: 'fit-content',
  textWrap: 'nowrap',
  borderBottom: `1px solid transparent`,
  transition: 'all 0.3s',
  '&:hover': {
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.color.primary[2]}`,
  },
}))

export const ListFeatures = emotionStyled('ul')(() => ({
  listStyleType: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  flexWrap: 'wrap',
  margin: '24px 0 0 0',
}))

export const ItemFeature = emotionStyled('li')(() => ({
  display: 'flex',
  gap: '4px',
  flex: 1,
  backgroundColor: theme.color.primary[2],
  borderRadius: '8px',
  padding: '4px 8px',
  color: theme.color.primary[9],
  width: 'fit-content',
}))

export const ContainerInfo = emotionStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}))

export const Subtitle = emotionStyled('p')(() => ({
  backgroundColor: theme.color.primary[9],
  color: theme.color.primary[2],
  padding: '4px 8px',
  borderRadius: '8px',
  width: 'fit-content',
  marginBottom: '8px',
}))
