import axios from 'axios'

export const createWebStore = (dataForm) => async (dispatch) => {
  dispatch({
    type: 'CREATE_STORE',
    payload: { isLoading: true, isError: false },
  })

  let storeCreated = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/php/GestionSeguridad.php?type=GSRegistracion&tiendaid=&api_key=&nombre=${dataForm.name_company}&nombre_empresa=${dataForm.name_company}&email=${dataForm.email}&clave1=${dataForm.password}&tipo_contribuyente=${dataForm.type}&lenguage_id=2&sessionid=&soy_cliente=1&es_empresa=1&es_empresa_free=1`,
  )

  dispatch({
    type: 'CREATE_STORE',
    payload: {
      isLoading: false,
      isErrorMessage: storeCreated?.data?.mensaje,
      data: storeCreated?.data || {},
    },
  })
}
