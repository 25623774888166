import { useEffect, useState } from 'react'
import { useTranslationHook } from '../../hooks/useTranslationHook'
import { ButtonStyled, Container, ContainerButtons } from './styles'
import { useNavigate } from 'react-router-dom'

export const Languages = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  const { lang, handleLang } = useTranslationHook()
  const navigate = useNavigate()

  function handleChangeLang(lang) {
    navigate('/')
    handleLang(lang)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Container isScrolled={isScrolled}>
      <ContainerButtons>
        <ButtonStyled
          onClick={() => handleChangeLang('es')}
          isScrolled={isScrolled}
          side={'left'}
          selected={lang === 'es'}
        >
          ES
        </ButtonStyled>
        <ButtonStyled
          onClick={() => handleChangeLang('en')}
          isScrolled={isScrolled}
          side={'right'}
          selected={lang === 'en'}
        >
          EN
        </ButtonStyled>
      </ContainerButtons>
    </Container>
  )
}
