import styled from "@emotion/styled";
import { theme } from "../../theme/theme";
import { Box } from "@mui/material";

export const TitleStyled = styled("h2")(() => ({
  fontSize: "32px",
  fontWeight: "bold",
  textAlign: "center",
  marginTop: "60px",
}));

export const Container = styled(Box)(() => ({
  backgroundColor: theme.color.primary[2],
  borderTop: `1px solid ${theme.color.black}`,
}));

export const Carrousel = styled(Box)(() => ({
  width: "100%",
  backgroundColor: theme.color.primary[2],
  padding: "24px 120px 60px 120px ",
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
  rowGap: "32px",
  alignItems: "center",
  justifyContent: "center",
}));

export const Item = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "140px",
  height: "140px",
  flexDirection: "column",
}));
