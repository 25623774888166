import { useTranslationHook } from "../../hooks/useTranslationHook";
import {
  Answer,
  Container,
  ContainerQuestions,
  Question,
  QuestionWrapper,
} from "./styles";
import { qaMock } from "../../utils/mockData";
import { TitleStyled } from "../styles";
import { Plus } from "@phosphor-icons/react";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";

export const Questions = () => {
  const [openedQuestions, setOpenedQuestions] = useState([]);
  const { lang, translations } = useTranslationHook();
  const isMobile = useMediaQuery("(max-width: 760px)");

  function handleOpenQuestion(idQuestion) {
    setOpenedQuestions((prev) =>
      prev?.includes(idQuestion)
        ? prev?.filter((ids) => ids !== idQuestion)
        : [...prev, idQuestion]
    );
  }

  return (
    <Container id={"questions"} isMobile={isMobile}>
      <TitleStyled>
        {translations.qa.frequentQuestions.toUpperCase()}
      </TitleStyled>
      <ContainerQuestions>
        {qaMock[lang].map((qa) => {
          const isOpen = openedQuestions?.includes(qa?.id);
          return (
            <QuestionWrapper key={qa.id}>
              <Question
                onClick={() => handleOpenQuestion(qa?.id)}
                isOpen={isOpen}
              >
                <Plus size={24} weight={"regular"} />
                <span>{qa.question}</span>
              </Question>
              {isOpen && <Answer>{qa.answer}</Answer>}
            </QuestionWrapper>
          );
        })}
      </ContainerQuestions>
    </Container>
  );
};
