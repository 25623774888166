import { useTranslationHook } from '../../hooks/useTranslationHook'
import { informationMock } from '../../utils/mockData'
import {
  Container,
  ContainerItems,
  Item,
  ItemDesc,
  ItemTitle,
  TitleInformation,
} from './styles'
import { ButtonNewWebsite } from './ButtonNewWebsite'
import { Features } from './Features'
import { useMediaQuery } from '@mui/material'
import { Icons } from '../../utils/icons'

export const Information = () => {
  const { lang, translations } = useTranslationHook()
  const isMobile = useMediaQuery('(max-width: 760px)')

  return (
    <Container id={'functionalities'} isMobile={isMobile}>
      <div
        style={{
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
          position: 'relative',
          width: 'fit-content',
        }}
      >
        <TitleInformation>
          {translations.information.oneClickAway.toUpperCase()}
        </TitleInformation>
        <ButtonNewWebsite text={translations.getStarted} />
      </div>
      <ContainerItems isMobile={isMobile}>
        {informationMock[lang]?.map((info) => {
          //@ts-ignore
          const Component = Icons?.[info.icon]

          return (
            <Item key={info.id} isMobile={isMobile}>
              <Component size={32} style={{ marginTop: '4px' }} />
              <div style={{ flex: 1 }}>
                <ItemTitle>
                  <p>{info.title.toUpperCase()}</p>
                </ItemTitle>
                <ItemDesc>{info.description}</ItemDesc>
              </div>
            </Item>
          )
        })}
      </ContainerItems>
      <Features />
    </Container>
  )
}
