import { useTranslationHook } from '../../hooks/useTranslationHook'
import {
  ButtonAskStyled,
  ButtonContractStyled,
  Container,
  ContainerInfo,
  Item,
  ItemFeature,
  ListFeatures,
  ListStyled,
  PlanName,
  Price,
  Subtitle,
} from './styles'
import { generalInfoMock, plansMock } from '../../utils/mockData'
import { TitleStyled } from '../styles'
import { CheckCircle, WhatsappLogo } from '@phosphor-icons/react'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const Plans = () => {
  const { lang, translations } = useTranslationHook()
  const isMobile = useMediaQuery('(max-width:760px)')
  const navigate = useNavigate()

  function handleContactUs() {
    navigate(generalInfoMock.links.whatsapp)
  }

  function handleContract() {
    const contactForm = document.getElementById('contact')
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Container id={'plans'} isMobile={isMobile}>
      <TitleStyled>
        {translations.plans.availablePlans.toUpperCase()}
      </TitleStyled>
      <ListStyled isMobile={isMobile}>
        {plansMock[lang].map((plan) => {
          return (
            <Item key={plan.id} isMobile={isMobile}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  minHeight: '300px',
                }}
              >
                <Price>$ {plan.price}</Price>
                <PlanName>{plan.name}</PlanName>
                <p>{plan.description}</p>
                <ContainerInfo>
                  {plan?.targetAudience && (
                    <div>
                      <Subtitle>
                        {translations.plans.targetAudience?.toUpperCase()}
                      </Subtitle>
                      <p>{plan.targetAudience}</p>
                    </div>
                  )}
                  {plan?.limitations && (
                    <div>
                      <Subtitle>
                        {translations.plans.limitations?.toUpperCase()}
                      </Subtitle>
                      <p>{plan.limitations}</p>
                    </div>
                  )}
                </ContainerInfo>
                <ListFeatures>
                  {plan.features?.map((feature) => {
                    return (
                      <ItemFeature key={feature}>
                        <CheckCircle
                          size={16}
                          weight={'fill'}
                          style={{ minWidth: 'fit-content' }}
                        />
                        <span>{feature}</span>
                      </ItemFeature>
                    )
                  })}
                </ListFeatures>
              </div>
              <div style={{ display: 'flex', gap: '16px' }}>
                <ButtonContractStyled onClick={handleContract}>
                  {translations.plans.contract}
                </ButtonContractStyled>
              </div>
              <ButtonAskStyled onClick={handleContactUs}>
                <WhatsappLogo size={24} />
                <span>{translations.plans.askUs}</span>
              </ButtonAskStyled>
            </Item>
          )
        })}
      </ListStyled>
    </Container>
  )
}
