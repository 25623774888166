// MENU
export const menu = [
  {
    id: 1,
    name: 'Functionalities',
    href: '#functionalities',
    openInNewTab: false,
  },
  {
    id: 2,
    name: 'Questions',
    href: '#questions',
    openInNewTab: false,
  },
  {
    id: 3,
    name: 'Plans',
    href: '#plans',
    openInNewTab: false,
  },
  {
    id: 4,
    name: 'Contact',
    href: '#contact',
    openInNewTab: false,
  },
  {
    id: 5,
    name: 'Login',
    href: 'https://admin.develshops.com/',
    openInNewTab: true,
  },
]

// FEATURES SECTION
export const informationMock = {
  en: [
    {
      id: 1,
      title: 'Software',
      description: `We specialize in software development and offer customized products, projects and applications that are tailored to the unique needs of the organizations with which we collaborate and provide guidance.`,
      icon: 'AppWindow',
    },
    {
      id: 2,
      title: 'Hardware',
      description:
        'We are an innovative and young company that uses IoT concepts to design hardware equipment and optimize processes in various environments.',
      icon: 'Laptop',
    },
    {
      id: 3,
      title: 'Services',
      description:
        'Our IT solutions are designed to meet your evolving technology needs, with flexible and scalable solutions that can help drive productivity within your business.',
      icon: 'Headset',
    },
  ],
  es: [
    {
      id: 1,
      title: 'Software',
      description: `Nos especializamos en desarrollo de software y ofrecemos productos personalizados, proyectos y aplicaciones que se adaptan a las necesidades únicas de las organizaciones con las que colaboramos y brindamos orientación.`,
      icon: 'AppWindow',
    },
    {
      id: 2,
      title: 'Hardware',
      description:
        'Somos una empresa innovadora y joven que utiliza conceptos de IoT para diseñar equipos de hardware y optimizar procesos en diversos entornos.',
      icon: 'Laptop',
    },
    {
      id: 3,
      title: 'Servicios',
      description:
        'Nuestras soluciones de TI están diseñadas para satisfacer sus necesidades tecnológicas en evolución, con soluciones flexibles y escalables que pueden ayudar a impulsar la productividad dentro de su empresa.',
      icon: 'Headset',
    },
  ],
}

// FEATURES SECTION
export const featuresMock = {
  en: [
    'Productos y ventas ilimitadas',
    'Gestión de marcas, categorías, productos',
    'Mensajería con whatsapp',
    'Gestión y notificaciones de stock',
    'Productos y ventas ilimitadas',
    'Multiples monedas',
    'Gestión de marcas, categorías, productos',
    'Gestión y notificaciones de stock',
    'Multiples monedas',
    'Gestión de marcas, categorías, productos',
    'Gestión y notificaciones de stock',
    'Multiples monedas',
    'Gestión de marcas, categorías, productos',
    'Gestión y notificaciones de stock',
  ],
  es: [
    'Productos y ventas ilimitadas',
    'Gestión de marcas, categorías, productos',
    'Mensajería con whatsapp',
    'Gestión y notificaciones de stock',
    'Productos y ventas ilimitadas',
    'Multiples monedas',
    'Gestión de marcas, categorías, productos',
    'Gestión y notificaciones de stock',
    'Multiples monedas',
    'Gestión de marcas, categorías, productos',
    'Gestión y notificaciones de stock',
    'Multiples monedas',
    'Gestión de marcas, categorías, productos',
    'Gestión y notificaciones de stock',
  ],
}

// TECHNOLOGIES SECTION
export const techStackMock = [
  {
    name: 'PHP',
    image: 'https://cdn-icons-png.freepik.com/512/5968/5968532.png',
  },
  {
    name: 'Mercado pago',
    image:
      'https://allbike.com.ar/wp-content/uploads/2022/01/kuder-mercadopago-blanco-negro.png',
  },
  {
    name: 'MongoDB',
    image: 'https://www.svgrepo.com/show/306440/mongodb.svg',
  },
  {
    name: 'React',
    image: 'https://www.svgrepo.com/show/327388/logo-react.svg',
  },
  {
    name: 'Node',
    image: 'https://cdn-icons-png.flaticon.com/512/5968/5968322.png',
  },
  {
    name: 'PostgreSQL',
    image:
      'https://raw.githubusercontent.com/getoutreach/stacked/master/svg_variable/postgresql-lockup.svg',
  },
  {
    name: 'JWT',
    image: 'https://cdn.worldvectorlogo.com/logos/jwt-3.svg',
  },
  {
    name: 'JSON',
    image: 'https://cdn-icons-png.flaticon.com/512/136/136443.png',
  },
  {
    name: 'WhatsApp',
    image:
      'https://static.vecteezy.com/system/resources/previews/023/986/487/non_2x/whatsapp-logo-whatsapp-logo-transparent-whatsapp-icon-transparent-free-free-png.png',
  },
  {
    name: 'Stripe',
    image:
      'https://cdn.freebiesupply.com/images/large/2x/stripe-logo-white-on-blue.png',
  },
  {
    name: 'PickIt',
    image:
      'https://scontent.faep24-1.fna.fbcdn.net/v/t39.30808-1/410223985_907331387789869_4357786040359963360_n.jpg?stp=dst-jpg_s480x480&_nc_cat=103&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=95i5Jx3kgoMQ7kNvgHwZA1U&_nc_ht=scontent.faep24-1.fna&oh=00_AYB7u4k4Pj41RGSQP6tRZ6xwVPmD-69zfk51Ht8rfDirTg&oe=66CC3475',
  },
]

// QUESTIONS SECTION
export const qaMock = {
  en: [
    {
      id: 1,
      question: 'What web domain will my store have?',
      answer: `When you create your store, it will have a free domain that includes the Empretienda service, and it will be: tumarca.com.ar. You will be able to use it without issues or configure your own domain directly from your admin panel.`,
    },
    {
      id: 2,
      question: 'Does my store have a storage limit?',
      answer: 'You can access the administration from any device.',
    },
    {
      id: 3,
      question: 'Do I get an invoice for the monthly service payment?',
      answer:
        'Your online store comes with a free domain and an included security certificate, with the option to configure your own domain.',
    },
    {
      id: 4,
      question:
        'Do I need to know about design or programming to manage my store?',
      answer: 'Everything your store needs, all in one place.',
    },
    {
      id: 5,
      question:
        'Is the price in Argentinean pesos? How can I pay for the plan?',
      answer:
        'The price is in Argentinean pesos. You can pay for the plan through various available payment methods.',
    },
    {
      id: 6,
      question: 'Can I manage the store from my cell phone?',
      answer: 'Yes, you can manage the store from your cell phone.',
    },
    {
      id: 7,
      question: 'What technologies does Develshops use?',
      answer: 'Develshops uses modern web technologies to power your store.',
    },
  ],
  es: [
    {
      id: 1,
      question: '¿Qué dominio web tendrá mi tienda?',
      answer: `Cuando crees tu tienda, la misma va a tener un dominio gratuito que incluye el servicio de Empretienda y será: tumarca.com.ar. Podrás utilizar ese sin problemas o configurar tu dominio propio directamente desde tu panel administrador.`,
    },
    {
      id: 2,
      question: '¿Mi tienda tiene un límite de almacenamiento?',
      answer:
        'Podrás ingresar a la administración desde cualquier dispositivo.',
    },
    {
      id: 3,
      question: '¿Me realizan factura por el pago mensual del servicio?',
      answer:
        'Tu tienda online viene con un dominio gratuito y certificado de seguridad incluido, con posibilidad de configurar tu propio dominio.',
    },
    {
      id: 4,
      question:
        '¿Necesito saber de diseño o programación para administrar mi tienda?',
      answer: 'Todo lo que necesita tu tienda en un solo lugar.',
    },
    {
      id: 5,
      question:
        '¿El precio es en pesos argentinos? ¿Cómo puedo abonar el plan?',
      answer:
        'El precio es en pesos argentinos. Puedes abonar el plan a través de los diversos métodos de pago disponibles.',
    },
    {
      id: 6,
      question: '¿Puedo administrar la tienda desde mi celular?',
      answer: 'Sí, puedes administrar la tienda desde tu celular.',
    },
    {
      id: 7,
      question: '¿Qué tecnologías utiliza Develshops?',
      answer:
        'Develshops utiliza tecnologías web modernas para impulsar tu tienda.',
    },
  ],
}

// PLANS SECTION
export const plansMock = {
  en: [
    {
      id: 1,
      name: 'Initial',
      price: 1,
      description: 'Ideal for starting your business.',
      features: [
        'Customizable storefront with basic design templates',
        'Secure hosting with an SSL certificate',
        'Up to 50 products',
        'Basic analytics and reporting',
        'Email support with a 24-hour response time',
        'Payment gateway integration for local currencies',
        'Mobile-friendly design',
        'Access to a community forum for beginners',
      ],
      targetAudience:
        'Entrepreneurs who are just starting and want a cost-effective way to get their business online.',
      limitations:
        'Limited to 50 products and basic design templates, with no access to advanced features like custom domains or priority support.',
    },
    {
      id: 2,
      name: 'Full',
      price: 5,
      description: 'For those who want to grow their business.',
      features: [
        'All features from the Initial plan',
        'Unlimited products',
        'Advanced design customization with drag-and-drop functionality',
        'Access to premium design templates',
        'Priority email and chat support with a 12-hour response time',
        'Integrated marketing tools (SEO, email marketing, etc.)',
        'Detailed analytics and insights',
        'Support for multiple payment gateways',
        'Multi-language and multi-currency support',
        'Ability to use your own domain',
      ],
      targetAudience:
        'Small to medium-sized businesses looking to expand their online presence with advanced tools and more flexibility.',
      limitations:
        'Lacks some of the high-end features such as dedicated account management and extensive storage options.',
    },
    {
      id: 3,
      name: 'Dedicated',
      price: 10,
      description:
        'For those who want to take their business to the next level.',
      features: [
        'All features from the Full plan',
        'Dedicated account manager',
        'Priority 24/7 support with a 1-hour response time',
        'Enhanced security features with advanced threat protection',
        'Customizable checkout process',
        'Integration with third-party tools and platforms',
        'Automated abandoned cart recovery',
        'Unlimited storage and bandwidth',
        'Advanced shipping and tax settings',
        'Access to beta features and early releases',
        'Dedicated IP address',
      ],
      targetAudience:
        'Established businesses and e-commerce stores that require a high level of customization, security, and support to operate at scale.',
      limitations:
        'Higher price point, which may be prohibitive for smaller businesses.',
    },
  ],
  es: [
    {
      id: 1,
      name: 'Inicial',
      price: 1,
      description: 'Ideal para iniciar su negocio.',
      features: [
        'Tienda personalizable con plantillas de diseño básicas',
        'Alojamiento seguro con un certificado SSL',
        'Hasta 50 productos',
        'Análisis y reportes básicos',
        'Soporte por correo electrónico con un tiempo de respuesta de 24 horas',
        'Integración de pasarela de pago para monedas locales',
        'Diseño compatible con dispositivos móviles',
        'Acceso a un foro comunitario para principiantes',
      ],
      targetAudience:
        'Emprendedores que están comenzando y buscan una forma económica de llevar su negocio en línea.',
      limitations:
        'Limitado a 50 productos y plantillas de diseño básicas, sin acceso a funciones avanzadas como dominios personalizados o soporte prioritario.',
    },
    {
      id: 2,
      name: 'Completo',
      price: 5,
      description: 'Para los que quieren hacer crecer su negocio.',
      features: [
        'Todas las características del plan Inicial',
        'Productos ilimitados',
        'Personalización avanzada del diseño con funcionalidad de arrastrar y soltar',
        'Acceso a plantillas de diseño premium',
        'Soporte prioritario por correo electrónico y chat con un tiempo de respuesta de 12 horas',
        'Herramientas de marketing integradas (SEO, marketing por correo electrónico, etc.)',
        'Análisis e informes detallados',
        'Compatibilidad con múltiples pasarelas de pago',
        'Soporte multilingüe y multimoneda',
        'Posibilidad de usar tu propio dominio',
      ],
      targetAudience:
        'Pequeñas y medianas empresas que buscan expandir su presencia en línea con herramientas avanzadas y mayor flexibilidad.',
      limitations:
        'Carece de algunas características de alto nivel como gestión de cuentas dedicada y opciones de almacenamiento extensas.',
    },
    {
      id: 3,
      name: 'Dedicado',
      price: 10,
      description: 'Para los que quieren llevar su negocio al siguiente nivel.',
      features: [
        'Todas las características del plan Completo',
        'Administrador de cuentas dedicado',
        'Soporte prioritario 24/7 con un tiempo de respuesta de 1 hora',
        'Funciones de seguridad mejoradas con protección avanzada contra amenazas',
        'Proceso de pago personalizable',
        'Integración con herramientas y plataformas de terceros',
        'Recuperación automática de carritos abandonados',
        'Almacenamiento y ancho de banda ilimitados',
        'Configuración avanzada de envíos e impuestos',
        'Acceso a funciones beta y lanzamientos anticipados',
        'Dirección IP dedicada',
      ],
      targetAudience:
        'Negocios establecidos y tiendas de comercio electrónico que requieren un alto nivel de personalización, seguridad y soporte para operar a gran escala.',
      limitations:
        'Precio más alto, que puede ser prohibitivo para negocios más pequeños.',
    },
  ],
}

// FOOTER SECTION
export const generalInfoMock = {
  totalStoresCreated: 25,
  contactMail: 'contacto@develone.com',
  contactNumber: '5491135346497',
  address: 'Buenos Aires, Argentina',
  links: {
    twitter: 'https://www.facebook.com/develonetech/',
    instagram: 'https://www.instagram.com/develoneok/',
    facebook: 'https://www.facebook.com/develonetech/',
    whatsapp: 'https://wa.me/message/7NCKOV2R36JEP1',
  },
  showBot: true,
}

// META TAGS
export const metaTagsMock = {
  title: 'DevelShops',
  description: 'DevelShops, create your own online store today!',
  keywords: 'DevelShops, store, online, create, software, hardware, services',
}
