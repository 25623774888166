export const theme = {
  color: {
    black: '#030301',
    white: '#ffffff',
    primary: {
      1: '#030301',
      2: '#E2E4E0',
      3: '#59a4d3',
      4: '#E53D00',
      5: '#EBC3DB',
      6: '#322F20',
      7: '#666666',
      8: '#D9D9D9',
      9: '#2c56ff',
      10: '#cbde96',
      11: '#ffaf11',
    },
    secondary: {
      1: '#E53D00',
      2: '#f4f3f3',
      3: '#CAFB46',
      4: '#DDA0DD',
      5: '#EE82EE',
    },
    text: {
      primary: {
        1: '#000000',
        2: '#333333',
        3: '#666666',
        4: '#999999',
        5: '#CCCCCC',
      },
      secondary: {
        1: '#FFFFFF',
        2: '#F0F0F0',
        3: '#E0E0E0',
        4: '#D0D0D0',
        5: '#C0C0C0',
      },
    },
  },
}
