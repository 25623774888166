import translations from './../lang/translations.json'

export const useTranslationHook = () => {
  const lang = localStorage.getItem('lang') ?? 'es'

  return {
    lang,
    translations: translations[lang],
    handleLang: (lang) => {
      localStorage.setItem('lang', lang)
      window.location.reload()
    },
  }
}
