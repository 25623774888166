import { Cursor, CursorClick } from '@phosphor-icons/react'
import { Button, TextButton } from './styles'
import { theme } from '../../theme/theme'
import { useState } from 'react'

export const ButtonNewWebsite = ({ text }: { text: string }) => {
  const [isHover, setIsHover] = useState(false)

  const handleMouseLeave = () => {
    setIsHover(false)
  }
  return (
    <Button
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'absolute', top: '24px', right: '-16px' }}
      onClick={() => window.open('https://admin.develshops.com/')}
    >
      {isHover ? (
        <CursorClick size={'40px'} color={theme.color.white} />
      ) : (
        <Cursor size={'40px'} color={theme.color.white} />
      )}
      <TextButton className={isHover ? 'visible' : ''}>
        <span>{text}</span>
      </TextButton>
    </Button>
  )
}
