import { ListLinks } from './List'
import { ContainerMenu } from './styles'

//@ts-ignore
export const MenuResponsive = () => {
  return (
    <ContainerMenu>
      <ListLinks isMenu={true} />
    </ContainerMenu>
  )
}
