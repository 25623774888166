import styled from "@emotion/styled";
import { theme } from "../../theme/theme";

export const ContainerNavTab = styled("div")(({ isScrolled }) => ({
  zIndex: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 40px",
  borderRadius: isScrolled ? "24px" : "",
  backgroundColor: isScrolled ? theme.color.primary[2] : theme.color.black,
  transition:
    "border-radius 0.3s ease, background-color 0.3s ease, top 0.3s ease",
  color: theme.color.white,
  position: "sticky",
  top: isScrolled ? "8px" : 0,
  border: isScrolled ? `1px solid ${theme.color.primary[8]}` : "none",
}));

export const Title = styled("h1")(({ isScrolled }) => ({
  color: isScrolled ? theme.color.black : theme.color.primary[2],
  margin: 0,
  "&:hover": {
    cursor: "pointer",
  },
}));

export const ContainerMenuLang = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
}));

export const ItemStyled = styled("li")(
  ({ isScrolled = false, isMenu = false }) => ({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: 0,
    transition: ".3s",
    backgroundColor:
      isMenu || isScrolled ? theme.color.primary[2] : theme.color.black,
    a: {
      transition: "0.3s",
      "&:hover": {
        color: theme.color.primary[9],
        transition: "0.3s",
      },
    },
    "&:hover": {
      transition: ".3s",
    },
  })
);

export const LinkStyled = styled("a")(
  ({ isScrolled, isActiveSection, isMenu }) => ({
    backgroundColor:
      isMenu || isScrolled
        ? `${theme.color.primary[2]} !important`
        : `${theme.color.black} !important`,
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: isActiveSection ? "8px" : "8px 0px",
    textDecoration: "none",
    borderBottom: `1px solid ${
      isActiveSection && isScrolled ? theme.color.primary[2] : theme.color.black
    }`,
    color: isMenu
      ? theme.color.black
      : isActiveSection
      ? theme.color.primary[9]
      : isScrolled
      ? theme.color.black
      : theme.color.primary[2],
  })
);

export const ListStyled = styled("ul")(({ isMenu }) => ({
  display: "flex",
  flexDirection: isMenu ? "column" : "row",
  alignItems: isMenu ? "flex-start" : "center",
  gap: "24px",
  fontSize: "13px",
  fontWeight: "bold",
  listStyleType: "none",
}));

export const ButtonStyled = styled("button")(
  ({ side, selected, isScrolled, isMobile }) => ({
    fontFamily: "Cabin, sans-serif",
    backgroundColor: "transparent",
    borderRadius: side === "left" ? "4px 0 0 4px" : "0 4px 4px 0",
    color: selected
      ? theme.color.primary[9]
      : isScrolled
      ? theme.color.black
      : theme.color.primary[2],
    svg: {
      fill: isMobile && isScrolled ? theme.color.black : theme.color.primary[2],
    },
    fontWeight: "bold",
    fontSize: "13px",
    padding: "4px",
    border: "none",
    "&:hover": {
      cursor: "pointer",
    },
  })
);

export const ContainerButtons = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
}));

export const Container = styled("div")(({ isScrolled }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "4px",
  borderRadius: "4px",
  border: `1px solid ${
    isScrolled ? theme.color.black : theme.color.primary[2]
  }`,
}));

export const ContainerMenu = styled("div")(() => ({
  position: "absolute",
  top: "40px",
  left: "-80px",
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
  backgroundColor: theme.color.primary[2],
  color: theme.color.black,
  padding: "24px",
  border: `1px solid ${theme.color.primary[8]}`,
}));
