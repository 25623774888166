import emotionStyled from "@emotion/styled";
import { theme } from "../../theme/theme";

export const Container = emotionStyled("div")(({ isMobile }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: isMobile ? "100px 16px" : "120px",
  backgroundColor: theme.color.primary[8],
}));

export const ContainerQuestions = emotionStyled("ul")(() => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.color.primary[8],
  listStyleType: "none",
}));

export const QuestionWrapper = emotionStyled("li")(() => ({
  overflow: "hidden",
  "&:first-of-type h3": {
    borderTop: "none",
  },
}));

export const Question = emotionStyled("h3")(({ isOpen = false }) => ({
  fontSize: "16px",
  borderTop: `1px solid ${
    isOpen ? theme.color.primary[9] : theme.color.primary[1]
  }`,
  display: "flex",
  alignItems: "center",
  padding: "24px",
  gap: "16px",
  fontWeight: 100,
  backgroundColor: isOpen ? theme.color.primary[9] : "transparent",
  color: isOpen ? theme.color.primary[8] : theme.color.primary[1],
  svg: {
    transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
    transition: "all 0.4s",
    fill: isOpen ? theme.color.primary[8] : theme.color.primary[1],
  },
  transition: "all 0.4s",

  "&:hover": {
    cursor: "pointer",
  },
}));

export const Answer = emotionStyled("p")(() => ({
  padding: "24px",
  fontSize: "14px",
  lineHeight: "24px",
}));
