import { useLocation, useNavigate } from 'react-router-dom'
import { Languages } from './Languages'
import {
  ButtonStyled,
  ContainerMenuLang,
  ContainerNavTab,
  Title,
} from './styles'
import { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { List, X } from '@phosphor-icons/react'
import { MenuResponsive } from './MenuResponsive'
import { ListLinks } from './List'

export const NavTab = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const menuRef = useRef(null)

  const { hash } = useLocation()
  const isMobile = useMediaQuery('(max-width: 760px)')
  const navigate = useNavigate()

  function handleShowMenu() {
    setShowMenu((prev) => !prev)
  }

  function handleTitleClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate('/')
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [hash])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <ContainerNavTab isScrolled={isScrolled}>
      <Title onClick={handleTitleClick} isScrolled={isScrolled}>
        DEVELSHOPS
      </Title>
      {isMobile ? (
        <div style={{ position: 'relative' }} ref={menuRef}>
          <ButtonStyled
            isMobile={isMobile}
            isScrolled={isScrolled}
            onClick={handleShowMenu}
          >
            {showMenu ? <X size={32} /> : <List size={32} />}
          </ButtonStyled>
          {showMenu && <MenuResponsive />}
        </div>
      ) : (
        <ContainerMenuLang>
          <ListLinks isScrolled={isScrolled} hash={hash} isMenu={false} />
          <Languages />
        </ContainerMenuLang>
      )}
    </ContainerNavTab>
  )
}
