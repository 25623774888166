import { NavTab } from './components/nav-tab/NavTab'
import { Information } from './components/information/Information'
import { CarrouselTechStack } from './components/techStack/CarrouselTechStack'
import { Questions } from './components/questions/Questions'
import { Plans } from './components/plans/Plans'
import { Contact } from './components/contact/Contact'
import { Footer } from './components/footer/Footer'
import store from './redux/store'
import { Provider } from 'react-redux'
import { Home } from './components/home/Home'
import { BrowserRouter as Router } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { generalInfoMock, metaTagsMock } from './utils/mockData'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ContainerBot } from './components/footer/styles'
import { WhatsappLogo } from '@phosphor-icons/react'

const App = () => {
  function handleChatBot() {
    console.log('ChatBot')
  }

  return (
    <Router>
      <Provider store={store}>
        <Helmet>
          <title>{metaTagsMock?.title}</title>
          <link rel="icon" href="./favicon.png" />
          <meta name="description" content={metaTagsMock?.description} />
          <meta name="keywords" content={metaTagsMock?.keywords} />
        </Helmet>
        <ToastContainer
          position="bottom-right"
          hideProgressBar={true}
          closeOnClick={false}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          theme="dark"
        />
        <NavTab />
        <Home />
        <Information />
        <CarrouselTechStack />
        <Questions />
        <Plans />
        <Contact />
        <Footer />
        {generalInfoMock?.showBot && (
          <ContainerBot onClick={handleChatBot}>
            <WhatsappLogo size={32} weight="fill" />
          </ContainerBot>
        )}
      </Provider>
    </Router>
  )
}

export default App
