import styled from '@emotion/styled'
import { theme } from '../../theme/theme'

export const ContainerStyled = styled('div')(({ isMobile }) => ({
  padding: isMobile ? '120px 16px' : '140px 80px',
  fontFamily: 'Noto Serif JP',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: `linear-gradient(180deg, ${theme.color.primary[9]} 0%, ${theme.color.primary[2]} 100%)`,
  position: 'relative',
  flexDirection: 'column',
}))

export const ContainerLeftStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '13px',
  color: theme.color.white,
  fontSize: '13px',
  width: '50%',
}))

export const ContainerTitle = styled('div')(() => ({
  margin: '0 auto',
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  gap: '13px',
  fontSize: '13px',
  fontFamily: 'Noto Serif JP',
  justifyContent: 'space-between',
  paddingBottom: '13px',
  position: 'relative',
}))

export const TextDesc = styled('p')(() => ({
  fontSize: '15px',
  color: theme.color.black,
  paddingBottom: '4px',
  borderBottom: `1px solid ${theme.color.black}`,
}))

export const ContainerDesc = styled('div')(() => ({
  display: 'flex',
  gap: '32px',
  fontFamily: 'Cabin',
  alignItems: 'center',
  width: '100%',
  marginTop: '13px',
}))

export const ButtonStyled = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '24px',
  gap: '8px',
  fontSize: '15px',
  padding: '12px 20px',
  flexShrink: 0,
  fontWeight: 'bold',
  fontFamily: 'Cabin',
  width: 'fit-content',
  position: 'absolute',
  top: '-24px',
  right: '24px',
  backgroundColor: theme.color.primary[2],
  color: theme.color.black,
  transition: '.2s',
  border: `1px solid ${theme.color.primary[2]}`,
  '&:hover': {
    borderColor: theme.color.black,
    transition: '.2s',
    cursor: 'pointer',
  },
}))

export const ButtonCreateStore = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  gap: '8px',
  fontSize: '16px',
  padding: '16px',
  fontWeight: 'bold',
  fontFamily: 'Cabin',
  width: 'fit-content',
  border: `1px solid ${theme.color.primary[9]}`,
  backgroundColor: theme.color.primary[9],
  color: theme.color.primary[2],
  transition: '.2s',
  marginTop: '24px',
  '&:hover': {
    border: `1px solid ${theme.color.black}`,
    backgroundColor: theme.color.black,
    color: theme.color.primary[2],
    transition: '.2s',
    cursor: 'pointer',
  },
}))

export const TitleStyled = styled('h2')(() => ({
  fontSize: '64px',
  margin: '0 0 40px 0',
  textAlign: 'left',
  fontFamily: 'Cabin',
  color: theme.color.primary[2],
  backgroundColor: theme.color.primary[9],
  borderRadius: '24px 24px 0 0',
  padding: '32px 16px',
  border: `1px solid ${theme.color.black}`,
}))

export const ContainerDescription = styled('div')(() => ({
  fontSize: '16px',
  width: '400px',
  margin: '0 0 40px 0',
  textAlign: 'left',
  fontFamily: 'Cabin',
  color: theme.color.primary[2],
  backgroundColor: theme.color.primary[9],
  border: `1px solid ${theme.color.black}`,
  padding: '32px 16px',
  position: 'absolute',
  borderRadius: '24px 24px 24px 0',
  right: '24px',
  bottom: '80px',
}))

export const ContainerDescription2 = styled('div')(() => ({
  // background: theme.color.black,
  fontSize: '16px',
  width: '80%',
  textAlign: 'left',
  fontFamily: 'Cabin',
  color: theme.color.black,
  border: `1px solid ${theme.color.primary[9]}`,
  padding: '32px 16px',
  borderRadius: '8px',
}))
