import { techStackMock } from '../../utils/mockData'
import { Carrousel, Container, Item, TitleStyled } from './styles'
import { useTranslationHook } from '../../hooks/useTranslationHook'

export const CarrouselTechStack = () => {
  const { translations } = useTranslationHook()

  return (
    <Container>
      <TitleStyled>
        {translations?.information?.techUsed?.toUpperCase()}
      </TitleStyled>
      <Carrousel>
        {techStackMock?.map((techStack) => (
          <Item key={techStack.name}>
            <img
              style={{
                width: '120px',
                padding: '8px',
                borderRadius: '24px',
              }}
              src={techStack.image}
              alt={techStack.name}
            />
            <p>{techStack.name}</p>
          </Item>
        ))}
      </Carrousel>
    </Container>
  )
}
