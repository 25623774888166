import { useEffect, useState } from 'react'
import { useTranslationHook } from '../../hooks/useTranslationHook'
import {
  ButtonStyled,
  CardDesc,
  Container,
  ContainerInput,
  ContainerSuccessStore,
  CountStores,
  FormStyled,
  InputStyled,
  ItemDataStore,
  LabelText,
  MiniTitle,
  StyledCheckbox,
} from './styles'
import { useMediaQuery } from '@mui/material'
import { CheckCircle, Storefront } from '@phosphor-icons/react'
import { theme } from '../../theme/theme'
import { useDispatch, useSelector } from 'react-redux'
import { createWebStore } from '../../redux/actions'
import { generalInfoMock } from '../../utils/mockData'
import { toast } from 'react-toastify'
import ClipLoader from 'react-spinners/ClipLoader'

export const Contact = () => {
  const [values, setValues] = useState({
    name_company: '',
    email: '',
    password: '',
    password_repeated: '',
    type: '4',
    isNotRobot: false,
  })

  const { data, isLoading, isErrorMessage } = useSelector(
    (state) => state.store,
  )
  console.log('Contact  isLoading:', isLoading)
  console.log('Contact  isError:', isErrorMessage)
  console.log('Contact  data:', data)

  const { translations } = useTranslationHook()
  const isMobile = useMediaQuery('(max-width: 760px)')
  const dispatch = useDispatch()

  function handleOnChange(e) {
    const { name, value } = e.target

    const regex = /^[a-zA-Z0-9]*$/

    if (name === 'name_company' && !regex.test(value)) {
      return
    }

    setValues({
      ...values,
      [name]: name === 'isNotRobot' ? e.target.checked : value,
    })
  }

  function handleCreateStore() {
    if (
      !values?.password ||
      !values?.password_repeated ||
      !values?.email ||
      !values?.name_company ||
      !values?.isNotRobot
    ) {
      toast(translations.messages.emptyFields)
      return
    }
    if (values.password !== values.password_repeated) {
      toast(translations.messages.passwordsNotMatch)
      return
    }
    if (!values.email.includes('@') || !values.email.includes('.com')) {
      toast(translations.messages.insertValidEmail)
      return
    }
    dispatch(createWebStore(values))
  }

  useEffect(() => {
    if (isErrorMessage) {
      toast(isErrorMessage)
      dispatch({
        type: 'CREATE_STORE',
        payload: { isErrorMessage: '' },
      })
    }
  }, [dispatch, isErrorMessage])

  return (
    <Container id={'contact'} isMobile={isMobile}>
      <h2 style={{ textAlign: 'center', width: '100%' }}>
        {translations.contact.createTitle.toUpperCase()}
      </h2>
      <div
        style={{
          display: 'flex',
          gap: '56px',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <CardDesc isMobile={isMobile}>
          <p style={{ padding: '24px' }}>{translations.contact.createDesc}</p>
          {generalInfoMock?.totalStoresCreated && (
            <CountStores>
              <h3>{translations.contact.countStores?.toUpperCase()}</h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Storefront size={32} color={theme.color.primary[2]} />
                <p style={{ fontSize: '24px' }}>
                  {generalInfoMock?.totalStoresCreated}
                </p>
              </div>
            </CountStores>
          )}
        </CardDesc>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            width: '100%',
          }}
        >
          {isLoading ? (
            <div style={{ margin: '0 auto' }}>
              <ClipLoader
                loading={isLoading}
                size={50}
                color={theme.color.primary[9]}
              />
            </div>
          ) : Object.keys(data)?.length ? (
            <ContainerSuccessStore>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  border: `1px solid ${theme.color.primary[9]}`,
                  padding: '8px',
                  borderRadius: '8px',
                }}
              >
                <h3>{translations.contact.successCreatedStore}</h3>
                <CheckCircle
                  size={24}
                  weight="fill"
                  color={theme.color.primary[9]}
                />
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <ItemDataStore>
                  <MiniTitle>{translations.contact.subdomain}:</MiniTitle>
                  <span>{data?.subdominio_godaddy}</span>
                </ItemDataStore>
                <ItemDataStore>
                  <MiniTitle>{translations.contact.domain}:</MiniTitle>
                  <span>{data?.shomepage}</span>
                </ItemDataStore>
                <ItemDataStore withRadius={false}>
                  <span>{data?.mensaje_mail}</span>
                </ItemDataStore>
              </div>
            </ContainerSuccessStore>
          ) : (
            <>
              <FormStyled isMobile={isMobile}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    justifyContent: 'space-between',
                  }}
                >
                  <ContainerInput>
                    <InputStyled
                      placeholder={translations.contact.name.toUpperCase()}
                      type="text"
                      name={'name_company'}
                      value={values.name_company}
                      onChange={handleOnChange}
                    />
                    <LabelText>{translations.contact.obligatory}</LabelText>
                  </ContainerInput>
                  <ContainerInput>
                    <InputStyled
                      placeholder={translations.contact.email.toUpperCase()}
                      type="text"
                      name={'email'}
                      value={values.email}
                      onChange={handleOnChange}
                    />
                    <LabelText>{translations.contact.obligatory}</LabelText>
                  </ContainerInput>
                  <ContainerInput>
                    <InputStyled
                      placeholder={translations.contact.password.toUpperCase()}
                      type="password"
                      name={'password'}
                      value={values.password}
                      onChange={handleOnChange}
                    />
                    <LabelText>{translations.contact.obligatory}</LabelText>
                  </ContainerInput>
                  <ContainerInput>
                    <InputStyled
                      placeholder={translations.contact.passwordRepeat.toUpperCase()}
                      type="password"
                      name={'password_repeated'}
                      value={values.password_repeated}
                      onChange={handleOnChange}
                    />
                    <LabelText>{translations.contact.obligatory}</LabelText>
                  </ContainerInput>
                </div>
              </FormStyled>
              <div
                style={{
                  margin: '16px auto',
                  backgroundColor: theme.color.primary[1],
                  padding: '16px',
                  borderRadius: '8px',
                  color: theme.color.primary[2],
                  display: 'flex',
                  alignContent: 'center',
                }}
              >
                <StyledCheckbox
                  type="checkbox"
                  id="isNotRobot"
                  name="isNotRobot"
                  onChange={handleOnChange}
                />
                <label
                  htmlFor="isNotRobot"
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  {translations.contact.isRobot}
                </label>
              </div>
              <ButtonStyled onClick={handleCreateStore}>
                <span>{translations.contact.create.toUpperCase()}</span>
              </ButtonStyled>
            </>
          )}
        </div>
      </div>
    </Container>
  )
}
