import styled from '@emotion/styled'
import { theme } from '../../theme/theme'

export const ContainerFooter = styled('footer')(() => ({
  display: 'flex',
  gap: '32px',
  width: '100%',
  padding: '80px 120px',
  backgroundColor: theme.color.primary[2],
  position: 'relative',
}))

export const SocialListStyled = styled('ul')(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  gap: '16px',
  listStyle: 'none',
  backgroundColor: theme.color.black,
  borderRadius: '0 0 8px 8px',
  padding: '8px',
  width: 'fit-content',
  svg: {
    fill: theme.color.primary[2],
  },
}))

export const DevelListStyled = styled('ul')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  listStyle: 'none',
  borderRadius: '8px',
  padding: '8px',
  marginLeft: '80px',
}))

export const ContainerBot = styled('div')(() => ({
  position: 'fixed',
  bottom: '24px',
  right: '24px',
  zIndex: 100,
  borderRadius: '100%',
  border: `1px solid ${theme.color.primary[8]}`,
  height: '40px',
  width: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    fill: theme.color.primary[1],
  },
  '&:hover': {
    cursor: 'pointer',
  },
}))
