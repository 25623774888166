import { Check, Eye } from '@phosphor-icons/react'
import { useTranslationHook } from '../../hooks/useTranslationHook'
import { featuresMock } from '../../utils/mockData'
import { TitleStyled } from '../styles'
import { FeatureItem, ListStyled } from './styles'
import { useState } from 'react'
import { useMediaQuery } from '@mui/material'

export const Features = () => {
  const [viewAll, setViewAll] = useState(false)
  const { lang, translations } = useTranslationHook()
  const isMobile = useMediaQuery('(max-width: 760px)')
  const quantityFeatures = 11

  function handleViewAll() {
    setViewAll(true)
  }

  return (
    <div>
      <TitleStyled isMobile={isMobile}>
        {translations.information.weHaveAll.toUpperCase()}
      </TitleStyled>
      <ListStyled isMobile={isMobile}>
        {featuresMock[lang]?.map((feature, i) => (
          <div key={i}>
            {!viewAll && i === quantityFeatures ? (
              <FeatureItem key={i} onClick={handleViewAll} isViewMore={true}>
                <Eye size={16} />
                {translations.information.viewAll}
              </FeatureItem>
            ) : (
              (viewAll || i < quantityFeatures) && (
                <FeatureItem key={i}>
                  <Check size={16} weight="bold" />
                  <span>{feature}</span>
                </FeatureItem>
              )
            )}
          </div>
        ))}
      </ListStyled>
    </div>
  )
}
