import emotionStyled from '@emotion/styled'
import { theme } from '../../theme/theme'

export const Container = emotionStyled('div')(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  width: '100%',
  padding: isMobile ? '100px 16px' : '120px',
  backgroundColor: theme.color.secondary[2],
  alignItems: isMobile ? 'center' : 'flex-start',
  justifyContent: 'center',
}))

export const CardDesc = emotionStyled('div')(({ isMobile }) => ({
  background: `linear-gradient(rgba(44, 86, 255, 1), rgba(44, 86, 255, 0.8)), url('./assets/bg_plan.svg')`,
  borderRadius: '8px',
  width: isMobile ? '100%' : '500px',
  color: theme.color.primary[2],
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  margin: '0 auto',
}))

export const CountStores = emotionStyled('div')(() => ({
  backgroundColor: theme.color.black,
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  color: theme.color.primary[2],
  fontSize: '16px',
  borderRadius: '0 0 8px 8px',
}))

export const FormStyled = emotionStyled('form')(({ isMobile }) => ({
  display: 'flex',
  gap: '40px',
  flexWrap: 'wrap',
  flexDirection: isMobile ? 'column' : 'row',
  margin: '0 auto',
}))

export const ContainerInput = emotionStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const LabelText = emotionStyled('p')(() => ({
  color: theme.color.primary[9],
  fontSize: '14px',
}))

export const ButtonStyled = emotionStyled('button')(() => ({
  fontSize: '20px',
  fontWeight: 'bold',
  backgroundColor: 'transparent',
  padding: '8px 16px',
  display: 'flex',
  gap: '16px',
  borderRadius: '8px',
  width: 'fit-content',
  outline: `1px solid ${theme.color.primary[6]}`,
  border: 'none',
  transition: '.3s',
  margin: '0 auto',
  '&:focus': {
    outline: `1px solid ${theme.color.primary[9]}`,
  },
  '&:hover': {
    backgroundColor: theme.color.black,
    color: theme.color.white,
    cursor: 'pointer',
    transition: '.3s',
  },
}))

export const InputStyled = emotionStyled('input')(() => ({
  fontFamily: 'Cabin',
  display: 'block',
  color: theme.color.primary[6],
  backgroundColor: 'transparent',
  borderRadius: '8px',
  width: '400px',
  padding: '8px 16px',
  border: 'none',
  outline: `1px solid ${theme.color.primary[6]}`,
  '&::placeholder': {
    color: theme.color.black,
  },
  '&:focus': {
    outline: `1px solid ${theme.color.primary[9]}`,
    border: 'none',
  },
}))

export const StyledCheckbox = emotionStyled.input`
  width: 20px;
  height: 20px;
  border: 2px solid blue;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: ${theme.color.primary[9]};
  }
  &:hover {
    background-color: ${theme.color.primary[9]};
  }
`

export const ContainerSuccessStore = emotionStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
}))

export const ItemDataStore = emotionStyled('p')(({ withRadius = true }) => ({
  borderBottom: `1px solid ${theme.color.primary[9]}`,
  padding: '8px 0',
  borderRadius: withRadius ? '8px 0 0 8px' : 0,
}))

export const MiniTitle = emotionStyled('span')(() => ({
  backgroundColor: theme.color.primary[9],
  color: theme.color.primary[2],
  padding: '8px',
  borderRadius: '8px 0 0 8px ',
  marginRight: '8px',
}))
