import {
  ButtonCreateStore,
  ButtonStyled,
  ContainerDescription2,
  ContainerStyled,
  ContainerTitle,
  TitleStyled,
} from './styles'
import { useTranslationHook } from '../../hooks/useTranslationHook'
import { useMediaQuery } from '@mui/material'
import { CaretDoubleDown, ChatCircleText } from '@phosphor-icons/react'
import { theme } from '../../theme/theme'

export const Home = () => {
  const { translations } = useTranslationHook()
  const isMobile = useMediaQuery('(max-width: 760px)')

  function handleScrollToContact() {
    const contactSection = document.getElementById('contact')
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <ContainerStyled isMobile={isMobile}>
      <ContainerTitle>
        <TitleStyled>{translations.home.createAndManage}</TitleStyled>
        <ButtonStyled onClick={handleScrollToContact}>
          <span>{translations.home.createStoreNow}</span>
          <CaretDoubleDown
            size={16}
            weight="bold"
            color={theme.color.primary[9]}
          />
        </ButtonStyled>
      </ContainerTitle>
      <ContainerDescription2>
        <p>{translations.home.description2}</p>
      </ContainerDescription2>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8px',
        }}
      > */}
      {/* <ButtonCreateStore onClick={handleScrollToContact}>
          {translations.home.createStoreNow}
        </ButtonCreateStore> */}
      {/* </div> */}
    </ContainerStyled>
  )
}
