import {
  FacebookLogo,
  InstagramLogo,
  WhatsappLogo,
  XLogo,
} from '@phosphor-icons/react'
import {
  ContainerBot,
  ContainerFooter,
  DevelListStyled,
  SocialListStyled,
} from './styles'
import { generalInfoMock } from '../../utils/mockData'
import { useNavigate } from 'react-router-dom'

export const Footer = () => {
  const navigate = useNavigate()

  function handleNavigate(link) {
    navigate(link)
  }

  return (
    <ContainerFooter>
      <SocialListStyled>
        {generalInfoMock.links.twitter && (
          <li>
            <XLogo
              size={32}
              weight="fill"
              onClick={() => handleNavigate(generalInfoMock.links.twitter)}
            />
          </li>
        )}
        {generalInfoMock.links.instagram && (
          <li>
            <InstagramLogo
              size={32}
              weight="fill"
              onClick={() => handleNavigate(generalInfoMock.links.instagram)}
            />
          </li>
        )}
        {generalInfoMock.links.facebook && (
          <li>
            <FacebookLogo
              size={32}
              weight="fill"
              onClick={() => handleNavigate(generalInfoMock.links.facebook)}
            />
          </li>
        )}
        {generalInfoMock.links.whatsapp && (
          <li>
            <WhatsappLogo
              size={32}
              weight="fill"
              onClick={() => handleNavigate(generalInfoMock.links.whatsapp)}
            />
          </li>
        )}
      </SocialListStyled>
      <DevelListStyled>
        <li>{generalInfoMock.address}</li>
        <li>{generalInfoMock.contactMail}</li>
        <li>{generalInfoMock.contactNumber}</li>
      </DevelListStyled>
    </ContainerFooter>
  )
}
