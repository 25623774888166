import { useTranslationHook } from '../../hooks/useTranslationHook'
import { menu } from '../../utils/mockData'
import { ItemStyled, LinkStyled, ListStyled } from './styles'

export const ListLinks = ({
  isScrolled = false,
  hash = '',
  isMenu = false,
}) => {
  const { translations } = useTranslationHook()

  return (
    <>
      <ListStyled isMenu={isMenu}>
        {menu?.map((item) => {
          return (
            <ItemStyled key={item.id} isScrolled={isScrolled}>
              <LinkStyled
                href={item.href}
                isScrolled={isScrolled}
                isActiveSection={hash === item.href}
                isMenu={isMenu}
                target={item?.openInNewTab ? '_blank' : '_self'}
              >
                {translations.menu[item.name.toLowerCase()].toUpperCase()}
              </LinkStyled>
            </ItemStyled>
          )
        })}
      </ListStyled>
    </>
  )
}
